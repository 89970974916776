import 'bootstrap';
import $ from "jquery";
import domReady from '@roots/sage/client/dom-ready';
import 'slick-carousel';

/**
 * Application entrypoint
 */
domReady(async () => {
  $('.testimonial-slider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    centerMode: true,
  });

  $('.menu-burger').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('menu-burger--active');
    $('.nav-primary').toggleClass('nav-primary--active');
  });

  $('.menu-item-has-children').on('click', function (e) {
    $(this).toggleClass('active');
    $(this).find('.sub-menu').toggleClass('active');
  })

  $('.accordion__title').on('click', function (e) {
    $(this).toggleClass('active');
    $(this).parent().find('.accordion__collapse').toggleClass('active');
  })

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
